import React, { FC } from 'react';
import NextImage from 'next/image';

import cx from 'classnames';
import sanityImg from 'utils/sanityImg';

import { MediaQueryPrefix } from 'types';
import { ImageBase } from 'lib/sanity/types';

type SanityImageProps = ImageBase & {
  className?: string;
  alt?: string | null;
  captionTitle?: string | null;
  captionDescription?: string | null;
  tabletImage?: ImageBase | null;
  laptopImage?: ImageBase | null;
  desktopImage?: ImageBase | null;
  quality?: number;
  aspectRatio?: number;
  layout?: 'responsive' | 'intrinsic' | 'fixed' | 'fill';
  sizes?: string;
  priority?: boolean;
  width?: number;
  height?: number;
  blur?: boolean;
};

const SanityImage: FC<SanityImageProps> = ({
  alt = '',
  _id,
  src,
  aspectRatio,
  dimensions,
  width,
  height,
  hotspot,
  crop,
  className,
  tabletImage,
  laptopImage,
  desktopImage,
  quality = 85,
  layout = 'responsive',
  sizes = '100vw',
  priority,
  blur,
}) => {
  const responsiveImages: Partial<Record<MediaQueryPrefix, ImageBase | null>> =
    {
      tablet: tabletImage,
      laptop: laptopImage,
      desktop: desktopImage,
    };

  const hasResponsiveImagesFor: MediaQueryPrefix[] = [];

  if (responsiveImages.tablet) hasResponsiveImagesFor.push('tablet');
  if (responsiveImages.laptop) hasResponsiveImagesFor.push('laptop');
  if (responsiveImages.desktop) hasResponsiveImagesFor.push('desktop');

  const sanityImgOption = {
    _id,
    src,
    aspectRatio,
    dimensions,
    hotspot,
    crop,
  };
  return (
    <>
      <NextImage
        className={cx(className, {
          [`${hasResponsiveImagesFor[0]}:hidden`]: !!hasResponsiveImagesFor[0],
        })}
        src={sanityImg(sanityImgOption)}
        alt={alt || ''}
        width={layout != 'fill' ? dimensions.width : width}
        height={layout != 'fill' ? dimensions.height : height}
        quality={quality}
        layout={layout}
        objectFit={'cover'}
        sizes={['fixed', 'intrinsic'].includes(layout) ? undefined : sizes}
        placeholder={blur ? 'blur' : 'empty'}
        blurDataURL={
          blur ? sanityImg(sanityImgOption, 200) : sanityImg(sanityImgOption)
        }
        priority={priority}
      />
      {hasResponsiveImagesFor.map((mediaQueryPrefix, index) => {
        const image = responsiveImages[mediaQueryPrefix];

        if (!image) return null;

        return (
          <NextImage
            className={cx(className, {
              [`${hasResponsiveImagesFor[index + 1]}:hidden`]:
                !!hasResponsiveImagesFor[index + 1],
            })}
            src={sanityImg(image)}
            alt={alt || ''}
            width={layout != 'fill' ? dimensions.width : width}
            height={layout != 'fill' ? dimensions.height : height}
            quality={quality}
            layout={layout}
            sizes={['fixed', 'intrinsic'].includes(layout) ? undefined : sizes}
            placeholder={blur ? 'blur' : 'empty'}
            blurDataURL={
              blur
                ? sanityImg(sanityImgOption, 200)
                : sanityImg(sanityImgOption)
            }
            priority={priority}
          />
        );
      })}
    </>
  );
};

export default SanityImage;
