import ConditionalWrapper from './ConditionalWrapper';
import NextLink from 'next/link';

/**
 * This is a general purpose component which can be used to render any link,
 * and wrap internal links with `next/link`.
 * @param {string} link The link to be rendered. It can be a relative or absolute URL.
 * @param {string} className A class name to pass down to the
 * @returns {React.ReactElement} A link element which may be wrapped by `next/link` if
 * it is an internal link.
 */
const Link: React.FC<{
  link: string;
  children: React.ReactNode;
  className?: string;
}> = ({ link, children, className }) => {
  const target = ['http', 'mailto', 'tel'].some((prefix) =>
    (link || '').startsWith(prefix),
  )
    ? '_blank'
    : undefined;
  const rel = target === '_blank' ? 'noindex nofollow' : '';
  const linkIsInternal = target !== '_blank';

  return (
    <ConditionalWrapper
      condition={linkIsInternal}
      wrapper={(children) => (
        <NextLink href={link} passHref={true}>
          {children}
        </NextLink>
      )}
    >
      <a href={link} target={target} rel={rel} className={className}>
        {children}
      </a>
    </ConditionalWrapper>
  );
};

export default Link;
