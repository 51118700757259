import React from 'react';
import Head from 'next/head';
import { MetaCard } from 'lib/sanity/types';

const DEFAULT_TITLE = 'Manhattan Hydraulics';
const DEFAULT_DESCRIPTION =
  'Manhattan Hydraulics helps companies design and ship outstanding products and digital experiences.';
const DEFAULT_IMAGE = 'https://hydraulics.nyc/og-image.png';

interface Props {
  // Manual title override
  title?: string;
  // Manual description override
  description?: string;
  // Manual image override — an absolute URL to an image
  image?: string;
  // Full meta object from Sanity
  seo?: MetaCard | null;
}

const Meta: React.FC<Props> = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  image = DEFAULT_IMAGE,
  seo,
}) => {
  const og = {
    title: seo?.og?.metaTitle || title,
    description: seo?.og?.metaDescription || description,
    image: seo?.og?.openImage?.src || image,
  };

  const twitter = {
    title: seo?.twitter?.twitterTitle || title,
    description: seo?.twitter?.twitterDescription || description,
    image: seo?.twitter?.twitterImage?.src || og.image,
  };

  return (
    <Head key="page">
      <title>{og.title}</title>
      <meta name="title" content={og.title} />
      <meta name="description" content={og.description} />
      <link rel="shortcut icon" href="/favicon.svg" type="image/x-icon" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={og.title} />
      <meta property="og:description" content={og.description} />
      <meta property="og:image" content={og.image} />
      <meta property="og:site_name" content={og.title} />
      <meta property="og:locale" content="en_US" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={twitter.title} />
      <meta property="twitter:description" content={twitter.description} />
      <meta property="twitter:image" content={twitter.image} />
    </Head>
  );
};

export default Meta;
