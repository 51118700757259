import { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { useUI } from 'hooks/useUI';
import { useSettings } from 'hooks/useSettings';

import recordConversion from 'lib/recordConversion';
import RichText from 'atoms/RichText';

const ContactUs = () => {
  const { setModalContent, showCalendly, setShowCalendly } = useUI();
  const { contactSettings } = useSettings();

  const [email, setEmail] = useState<string>('');
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    recordConversion();
    await fetch('/api/emails', {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    setModalContent(
      <div className="bg-yellow rounded-2xl p-3 top-0 mb-1">
        <h1>Thank you! We&apos;ll be in touch.</h1>
      </div>,
    );
  };

  if (showCalendly) {
    return (
      <>
        <div
          className="bg-yellow rounded-2xl p-3 top-0 mb-1"
          style={{ maxWidth: '400px' }}
        >
          <div className="flex flex-row justify-between">
            <p>Schedule a Meeting</p>
            <button
              onClick={() => {
                setModalContent(null), setShowCalendly(false);
              }}
            >
              <p>Close</p>
            </button>
          </div>
        </div>

        <div
          className="bg-white rounded-2xl px-3"
          style={{ height: '500px', overflow: 'hidden' }}
        >
          <InlineWidget
            url="https://calendly.com/hydraulics/hello"
            styles={{
              height: '500px',
            }}
            pageSettings={{
              hideLandingPageDetails: true,
              hideEventTypeDetails: true,
              hideGdprBanner: true,
            }}
          />
        </div>
      </>
    );
  }

  return (
    <div
      className="bg-yellow rounded-2xl p-3 top-0 mx-4"
      style={{ maxWidth: '400px' }}
    >
      <div className="flex flex-row justify-between border-b border-black/10 pb-3 mb-3">
        <p>Contact</p>
        <button onClick={() => setModalContent(null)}>
          <p>Close</p>
        </button>
      </div>
      <div className="mb-4">
        <RichText value={contactSettings.contactPrompt} />
      </div>
      <form
        action="/api/emails"
        method="post"
        className="flex p-2 rounded-md bg-white/75"
        onSubmit={onSubmit}
      >
        <input
          style={{ background: 'transparent' }}
          type="email"
          autoFocus
          placeholder="Enter email"
          value={email || ''}
          onChange={(e) => setEmail(e.target.value || '')}
          className="flex-grow"
        ></input>
        <button type="submit" className="flex items-center">
          Submit
          <svg
            className="ml-2"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 7L12 13.9282L4 13.9282L1.55766e-06 7L4 0.0717959L12 0.0717962L16 7Z"
              fill="#181818"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
