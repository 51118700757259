import sanityImgBuilder from 'utils/sanityImgBuilder';

import { ImageBase } from 'lib/sanity/types';

const Defaults = {
  QUALITY: 70,
  DPR: 1,
  OPTIONS: {},
  BLUR: 300,
};

const sanityImg = (
  image: ImageBase,
  blur?: number,
  options: object = Defaults.OPTIONS,
  quality: number = Defaults.QUALITY,
): string => {
  if (!image.src) console.error('image.src not found');
  const dpr: number = Defaults.DPR;
  const imageUrl: string = sanityImgBuilder(image, quality, dpr, blur);
  const optionSets: string[][] = Object.entries(options);
  if (optionSets.length) {
    const optionsString = optionSets
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return `${imageUrl}&${optionsString}`;
  } else {
    return imageUrl;
  }
};

export default sanityImg;
