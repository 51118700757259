import { PortableText, PortableTextProps } from '@portabletext/react';
import React, { FC } from 'react';
import RichTextComponents from './RichTextComponents';

type RichTextProps = Pick<PortableTextProps, 'value'>;

const RichText: FC<RichTextProps> = ({ value }) => {
  return <PortableText value={value} components={RichTextComponents} />;
};

export default RichText;
