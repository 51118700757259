import React, { useEffect, useRef, useState } from 'react';
import {
  PortableTextBlockComponent,
  PortableTextComponents,
  PortableTextListComponent,
  PortableTextMarkComponent,
  PortableTextListItemComponent,
} from '@portabletext/react';
import { Image } from 'lib/sanity/types';

import cx from 'classnames';
import ConditionalWrapper from './ConditionalWrapper';
import SanityImage from './SanityImage';
import GenericLink from './Link';

import * as Ui from 'contexts/ui';

const Underline: PortableTextMarkComponent = ({ children }) => {
  return (
    <span className="underline underline-offset-2 decoration-[0.05rem]">
      {children}
    </span>
  );
};

const Link: PortableTextMarkComponent<{
  _type: 'link';
  _key: string;
  link: string;
}> = ({ value, children }) => {
  // According to Sanity's types, annotations should always have a value.
  const { link } = value!;
  return (
    <GenericLink link={link || ''}>
      <span className="underline">{children}</span>
    </GenericLink>
  );
};

const UnorderedList: PortableTextListComponent = ({ children }) => {
  return (
    <ul className="ml-4 laptop:ml-0 list-outside list-[circle]">{children}</ul>
  );
};

const OrderedList: PortableTextListComponent = ({ children }) => {
  return (
    <ol className="ml-4 laptop:ml-0 list-outside list-decimal">{children}</ol>
  );
};

const UnorderedListItem: PortableTextListItemComponent = ({ children }) => {
  return <li>{children}</li>;
};

const OrderedListItem: PortableTextListItemComponent = ({ children }) => {
  return (
    <li>
      <span className="block">{children}</span>
    </li>
  );
};

const TextBlock: PortableTextBlockComponent = (block) => {
  const { style = 'normal' } = block.value || {};

  if (style === 'med') {
    return <h2>{block.children}</h2>;
  } else if (style === 'large') {
    return <h1>{block.children}</h1>;
  } else if (style === 'blockquote') {
    return (
      <blockquote className="bg-white p-8 text-large desktop:text-desktop-large rounded">
        {block.children}
      </blockquote>
    );
  }

  return <p>{block.children}</p>;
};

const InlineImage: PortableTextMarkComponent = ({ value, children }) => {
  const { link } = value;
  const spanRef = useRef<HTMLSpanElement>(null);
  const [containerTag, setContainerTag] = useState<string | undefined | null>(
    'normal',
  );
  const WrapperClassName =
    'whitespace-nowrap inline-flex items-center align-bottom';

  useEffect(() => {
    const _containerTag: string | undefined | null = spanRef.current
      ? spanRef.current.parentElement?.tagName
      : null;
    setContainerTag(_containerTag);
  }, [spanRef]);

  return (
    <span className={WrapperClassName} ref={spanRef}>
      <ConditionalWrapper
        condition={link}
        wrapper={(children) => (
          <GenericLink link={link} className={WrapperClassName}>
            {children}
          </GenericLink>
        )}
      >
        <>
          <span
            className={cx('inline-block mr-2 relative', {
              'h-4 w-4 laptop:h-5 laptop:w-5':
                containerTag === 'P' || !containerTag,
              'h-6 w-6 laptop:h-8 laptop:w-8': containerTag === 'H2',
              'h-8 w-8 laptop:h-12 laptop:w-12': containerTag === 'H1',
            })}
          >
            <SanityImage {...(value.image as Image)} layout="fill" />
          </span>
          {children}
        </>
      </ConditionalWrapper>
    </span>
  );
};

const CTA: PortableTextMarkComponent = ({ value, children }) => {
  const { link } = value;

  return (
    <GenericLink link={link} className="RichTextComponents__CTA">
      <span className="flex flex-wrap content-center largeButton p-6 bg-white hover:rounded-2xl transition-all duration-200">
        {children}
      </span>
    </GenericLink>
  );
};

const BlockquoteAttribution: PortableTextMarkComponent = ({
  value,
  children,
}) => {
  const { attribution } = value;

  return (
    <>
      {children}
      <span className="block text-small desktop:text-desktop-small text-black-tint mt-2">
        {attribution}
      </span>
    </>
  );
};

const TextColor: PortableTextMarkComponent = ({ value, children }) => {
  const { hexValue } = value;
  if (!children) return null;
  return <span style={{ color: `#${hexValue}` }}>{children}</span>;
};

const Calendly: PortableTextMarkComponent = ({ children }) => {
  const { setShowCalendly } = Ui.useContext();
  return (
    <button className="underline" onClick={() => setShowCalendly(true)}>
      {children}
    </button>
  );
};

export default {
  block: TextBlock,
  inlineImage: InlineImage,
  marks: {
    CTA: CTA,
    textColor: TextColor,
    inlineImage: InlineImage,
    blockquoteAttribution: BlockquoteAttribution,
    link: Link,
    underline: Underline,
    calendly: Calendly,
  },
  list: {
    bullet: UnorderedList,
    number: OrderedList,
  },
  listItem: {
    bullet: UnorderedListItem,
    number: OrderedListItem,
  },
} as PortableTextComponents;
