import React, { FC } from 'react';
import { Settings } from 'lib/sanity/types';

import ContactUsModal from 'components/modals/ContactUs';

import * as Ui from 'contexts/ui';

export type FooterProps = {
  settings: Settings;
};

const SimpleFooter: FC<FooterProps> = ({ settings }) => {
  const { setModalContent } = Ui.useContext();
  return (
    <footer className="w-full">
      <div className="content-max-width w-full">
        {/* Content */}
        <div className="grid gap-6 tablet:grid-cols-2 mb-12">
          <div>
            <h3 className="text-black pb-3 pl-2">Get in touch</h3>
            <a onClick={() => setModalContent(<ContactUsModal />)}>
              <h2 className="w-fit text-xl cursor-pointer bg-gray-05 hover:text-gray-04 p-2 rounded-lg">
                {settings.footerSettings.email}
              </h2>
            </a>
          </div>
          <div className="-ml-3">
            <h3 className="text-black pb-2 px-3">Our Collaborators</h3>
            {settings.footerSettings.socialMedia.map((s) => {
              return (
                <a
                  key={s._key}
                  className="text-gray-03 cursor-pointer hover:bg-black/5 py-2 px-3 block w-fit rounded-lg"
                  target="_blank"
                  rel="noreferrer"
                  href={s.url}
                >
                  <p>{s.name}</p>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;
