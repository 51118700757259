import imageUrlBuilder from '@sanity/image-url';

import { ImageBase } from 'lib/sanity/types';

const dataset = process.env.SANITY_DATASET || 'production';

export default (
  image: ImageBase,
  quality: number,
  dpr: number,
  blur?: number,
): string => {
  if (!process.env.SANITY_PROJECT_ID) {
    console.error('Sanity project id is missing');

    return '';
  }

  const builder = imageUrlBuilder({
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: dataset,
  });

  const imageAsImageObject = {
    asset: {
      _ref: image._id,
    },
    crop: image.crop,
    hotspot: image.hotspot,
  };
  if (blur) {
    return (
      builder
        .image(imageAsImageObject)
        .quality(quality)
        .dpr(dpr)
        .auto('format')
        .blur(blur)
        .url() || ''
    );
  } else {
    return (
      builder
        .image(imageAsImageObject)
        .quality(quality)
        .dpr(dpr)
        .auto('format')
        .url() || ''
    );
  }
};
